@tailwind base;
@tailwind components;
@tailwind utilities;

.streched-link:after
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: auto;

    background-color: rgba(0,0,0,0);
}
